import React, { useState, useEffect } from "react";
import WidgetButton from "./components/WidgetButton/WidgetButton";

function App() {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const loadConfig = async () => {
      const script = document.createElement("script");
      let location = document.getElementById("mitrol-widget-container").getAttribute("location")
      if(location[location.length -1] !== "/"){
        location += "/"
      }
      script.src = location + "env-config.js";
      script.async = true;
      script.onload = () => {
        const configName =
          document
            .getElementById("mitrol-widget-container")
            .getAttribute("data-config") || "default";
        const configData =
          window._env_.configurations[configName] ||
          window._env_.configurations["default"];
        setConfig(configData);
      };
      script.onerror = () => {
        console.error("Failed to load env-config.js");
      };
      document.body.appendChild(script);
    };

    loadConfig();
  }, []);

  const posicionarWidget = () => {
    switch (config?.bottom_pos) {
      case 1:
        return "2%";
      case 2:
        return "5%";
      case 3:
        return "10%";
      default:
        return "2%";
    }
  };

  return (
    <>
      {config ? (
        <div
          style={{
            position: "fixed",
            bottom: posicionarWidget(),
            right: "30px",
            zIndex: 999999,
          }}
        >
          <WidgetButton config={config} />
        </div>
      ) : null}
    </>
  );
}

export default App;
